<template>
	<div >
		<el-header class="el-header">
			<home-header></home-header>
		</el-header>
		<div class="main">
			<router-view></router-view>
		</div>
		<bottomView></bottomView>
	</div>
</template>
 
<script>
	import bottomView from "@/components/bottomView"
	import HomeHeader from '@/components/Header'
	export default {
		name: 'layout',
		components: {
			HomeHeader,
			bottomView
		},
		mounted(){
			// window.addEventListener("scroll", this.handleScroll);
		},
		methods:{
			handleScroll(e){
				// console.log("滚动了---------",e);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.el-header {
		height: 80px !important;
		background-color: transparent;
		padding: 0 !important;
		position: fixed;
		top: 0;
		width: 100%;
	}
	
	::v-deep .el-header{
		z-index: 999;
	}

	::v-deep .el-main {
		padding: 0 !important;
		height: calc(100vh - 50px);
		overflow: hidden;
		overflow-y: auto;
		background-color: indianred;
		background-color: #f6f9ff;
	}
	
	.main{
		min-height: 100vh;
		box-sizing: border-box;
		background-color: #f6f9ff;
	}

	.el-container {
		// height: calc(100vh - 200px) !important;
		background-color: #f6f9ff;
	}

	.bottom-container {
		width: 100%;
		height: 50px;
		background-color: #EEEEEE;
		// position: fixed;
		bottom: 0px;
		display: flex;
		flex-direction: row;
		align-items: center;
		z-index: 9999;
	}

	.bottom-text {
		margin-left: 30px;
		margin-right: 30px;
		font-size: 14px;
		color: #828282;
	}

	::v-deep .el-aside {
		.el-scrollbar__wrap {
			overflow: hidden;
		}
	}

	::v-deep.el-scrollbar__bar.is-horizontal {
		height: 0 !important;
		display: none;
	}
</style>